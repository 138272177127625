import {Controller} from 'stimulus';

export default class extends Controller {
  go(event) {
    if(!($(event.target).hasClass("btn") || $(event.target).hasClass("link"))){
      window.location.href = $(event.target).parents("tr").attr("href")
    }else{
      // event.stopPropagation();
    }
  }
}