// Entry point for the build script in your package.json

import '../assets/stylesheets/application.sass.scss';
import 'popper.js';
import 'expose-loader?exposes=$,jQuery!jquery';
import '@client-side-validations/client-side-validations';
import '@client-side-validations/simple-form/dist/simple-form.bootstrap4';
import 'bootstrap/js/src/alert';
import 'bootstrap/js/src/button';
import 'bootstrap/js/src/tooltip';
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/carousel';
import 'select2/dist/js/select2.full';
import 'bootstrap4-notify/bootstrap-notify';
import lightGallery from 'lightgallery/lightgallery.min';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import './controllers';

import Rails from '@rails/ujs'

document.documentElement.classList.remove('no-js');

Rails.start();

console.log('\n\n%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS. Do not enter or paste code that you do not understand.','font-weight: bold;color:#dc3545;font-size:18px;');
console.log('%c使用此控制台可能会使攻击者得以利用 Self-XSS（自跨站脚本）攻击来冒充你，并窃取你的信息。请勿输入或粘贴任何你不明白的代码。\n','font-weight: bold;color:#dc3545;font-size:18px;');
console.log('This system is for the use of authorized users only. Individuals using this computer system without authority, or in excess of their authority, are subject to having all of their activities on this system monitored and recorded by system personnel.In the course of monitoring individuals improperly using this system, or in the course of system maintenance, the activities of authorized users may also be monitored. Anyone using this system expressly consents to such monitoring and is advised that if such monitoring reveals possible evidence of criminal activity, system personnel may provide the evidence of such monitoring to law enforcement officials.');
console.log('本系统仅供授权人士访问，未经授权(或/和超出授权)的活动均将受到监控。如在监控中发现任何潜在的犯罪活动证据，相关日志可能被提交给执法官员。任何意图窃取、破坏或对系统安全性造成影响的活动，将被追究民事责任并可能构成刑事犯罪。');

// @ts-ignore
$(document).ready(()=>$("[data-select=true]").select2({ minimumResultsForSearch: 5 }));
$(document).ready(()=>$('[data-toggle="tooltip"]').tooltip());


$.notifyDefaults(
	{
	type: 'success',
  allow_dismiss: true,
  showProgressbar: true,
  delay: 5000,
  z_index: 99999,
  newest_on_top: true,
  animate: {
    enter: 'animated fadeInDown',
    exit: 'animated fadeOutUp',
  },
	template: `<div data-notify="container" class="alert alert-{0}" role="alert">
	 <button type="button" class="close" data-dismiss="alert" data-notify="dismiss" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
	<span data-notify="icon"></span>
	<span data-notify="title">{1}</span>
	<span data-notify="message">{2}</span>
	<div class="progress" data-notify="progressbar">
		<div class="progress-bar bg-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>
	</div>
	<a href="{3}" target="{4}" data-notify="url"></a>
</div>`
});

// @ts-ignore
$(document).ready(()=>{
  lightGallery(document.getElementById('lightgallery'), {
    plugins: [lgZoom, lgThumbnail],
    thumbnail: true,
    speed: 500
  });
  lightGallery(document.getElementById('lightgallery2'), {
    plugins: [lgZoom, lgThumbnail],
    thumbnail: true,
    speed: 500
  });
  lightGallery(document.getElementById('video-lightgallery'), {
    plugins: [lgVideo],
    speed: 500,
    thumbnails: true
  });
})

