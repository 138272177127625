import {Controller} from 'stimulus';

export default class extends Controller {
  copy(event) {
    let address = $(event.target).attr("address")
    let textArea = document.createElement('textarea');
    textArea.value = address;
    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    console.log(event.pageX)
   // @ts-ignore
    $.notify({
      message: "地址已复制",
    }, {
      allow_dismiss: false,
      showProgressbar: false,
      delay: 1000,
      element: 'body'
    });
  }
}